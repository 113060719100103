
import React,  { Component } from 'react';
import './Contact.css';

class Contact extends Component {
    componentDidMount() {
        const map = new window.BMapGL.Map('contactMap');
        const pointer = new window.BMapGL.Point(116.325795,39.982476);
        map.centerAndZoom(pointer, 15);
        map.enableScrollWheelZoom(true);
        var marker1 = new window.BMapGL.Marker(new window.BMapGL.Point(116.325795,39.982476));
        map.addOverlay(marker1)
    }
    openMapTab() {
        const url = 'https://map.baidu.com/search/%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA%E4%B8%AD%E5%85%B3%E6%9D%91%E5%A4%A7%E8%A1%9728-1%E5%8F%B7/@12949452.125,4835631,19z?querytype=s&da_src=shareurl&wd=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA%E4%B8%AD%E5%85%B3%E6%9D%91%E5%A4%A7%E8%A1%9728-1%E5%8F%B7&c=75&src=0&pn=0&sug=0&l=17&b=(11583844.795399603,3555192.9709986877;11585885.550718553,3556270.036305912)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2'
        window.open(url);
    }
    render() {
        return (
            <div className="contact">
                <div className='contact-wrap'>
                    <div className='contact-ercode'>
                        <div className='contact-ercode-icon'></div>
                        <p>扫码咨询</p>
                    </div>
                    <div className="contact-phone">
                        <p className='contact-phone-number'>
                            <span className='contact-phone-number-icon'></span>
                            <span className='contact-phone-number-value'>13905636915</span>
                        </p>
                        <p className='contact-address'>北京市海淀区中关村大街28-1号12层590室</p>
                    </div>
                    <div className="contact-address-map" id='contactMap' onClick={this.openMapTab}>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default Contact;
