import { Component } from 'react';
import './App.css';
import About from './components/About/About';
import Adv from './components/Adv/Adv';
import Contact from './components/Contact/Contact';
import FunIntro from './components/FunIntro/FunIntro';
import Hardware from './components/Hardware/Hardware';
import Header from './components/Header/Header';
import Help from './components/Help/Help';
import OnlineExp from './components/OnlineExp/OnlineExp';
import Summary from './components/Summary/Summary';
import 'wowjs/css/libs/animate.css';
import 'amfe-flexible';
import { WOW } from 'wowjs';
import Footer from './components/Footer/Footer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0
    }
    this.timer = null;
    this.goToSlide = this.goToSlide.bind(this);
    this.startSlider = this.startSlider.bind(this);
    this.getActiveDotClassName = this.getActiveDotClassName.bind(this)
  }
  componentDidMount() {
    new WOW().init();
    this.startSlider();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
  goToSlide(index) {
    const carousel = document.querySelector('.carousel');
    const carouselItems = carousel.querySelectorAll('.carousel-item');
    if (index < 0) {
      index = carouselItems.length - 1;
    } else if (index >= carouselItems.length) {
      index = 0;
    }
    this.setState({ currentIndex: index })
    carouselItems[index].classList.add('active');
  }
  startSlider() {
    const cIndex = this.state.currentIndex;
    const carousel = document.querySelector('.carousel');
    const carouselItems = carousel.querySelectorAll('.carousel-item');
    carouselItems[cIndex].classList.add('active');
    this.timer = setInterval(() => {
      carouselItems[this.state.currentIndex].classList.remove('active');
      this.goToSlide(this.state.currentIndex + 1);
    }, 5000);
  }
  getActiveDotClassName(index) {
    if (index === this.state.currentIndex) {
      return 'dot-item active'
    }
    return 'dot-item'
  }
  render() {
    return (
    <div className="App">
        <div className='main' id='home'>
          <div className='carousel'>
            <div className='carousel-item fadeOutDown'>
              <p className='carousel-item-title wow fadeInDown' data-wow-delay="0.5s">PCDN平台专业定制服务商</p>
              <p className='carousel-item-sub-title wow fadeInDown' data-wow-delay="0.5s">X86、小盒子、安卓全平台支持</p>
            </div>
            <div className='carousel-item item1 fadeOutDown'>
              <p className='carousel-item-title wow fadeInDown' data-wow-delay="0.5s">平台可对外提供API，方便下游平台接入</p>
              <p className='carousel-item-sub-title wow fadeInDown' data-wow-delay="0.5s">X86、小盒子、安卓全平台支持</p>
            </div>
            <div className='dot'>
              <div className={ this.getActiveDotClassName(0) }></div>
              <div className={ this.getActiveDotClassName(1) }></div>
            </div>
          </div>
          <Header />
          <Summary />
      </div>
      <div>
        <Adv />
        <FunIntro />
        <Hardware />
        <OnlineExp />
        <Help />
        <About />
        <Contact />
        <Footer />
      </div>
    </div>
  );
  }
}

export default App;
