import Title from '../Title/Title';
import './Adv.css';

function Adv() {
  return (
      <div className="advantage" id='advantage'>
          <div className='advantage-content'>
              <Title titleName="平台优势" />
              <div className='adv-list'>
                  <div className='adv-item wow slideInLeft' data-wow-offset="60">
                      <div className='adv-item-icon adv11'></div>
                      <div className='adv-item-title'>SAAS服务</div>
                      <div className='adv-item-detail'>服务部署更快，两个工作日内即可上线，无服务器费用，不需要自己运维，专心搞定业务即可</div>
                  </div>
                  <div className='adv-item wow slideInLeft' data-wow-offset="60">
                      <div className='adv-item-icon adv3'></div>
                      <div className='adv-item-title'>上游丰富</div>
                      <div className='adv-item-detail'>可免费对接现有多家优质上游平台API，如有其他未对接API，可定制开发，客户无感知升级</div>
                  </div>
                  <div className='adv-item wow slideInLeft' data-wow-offset="60">
                      <div className='adv-item-icon adv5'></div>
                      <div className='adv-item-title'>平台稳定</div>
                      <div className='adv-item-detail'>经过多家平台长期运营验证，稳定可靠</div>
                  </div>
                  <div className='adv-item wow slideInLeft' data-wow-offset="60">
                      <div className='adv-item-icon adv7'></div>
                      <div className='adv-item-title'>多端支持</div>
                      <div className='adv-item-detail'>现已支持X86服务器，电视盒子，后续即将推出路由器、摄像头等多种产品形态</div>
                  </div>
                  <div className='adv-item wow slideInRight' data-wow-offset="60">
                      <div className='adv-item-icon adv2'></div>
                      <div className='adv-item-title'>账单透明</div>
                      <div className='adv-item-detail'>平台客户直接与上游直签，无中间费用。账单金额及明细由上游直接发放</div>
                  </div>
                  
                  <div className='adv-item wow slideInRight' data-wow-offset="60">
                      <div className='adv-item-icon adv4'></div>
                      <div className='adv-item-title'>平台开发</div>
                      <div className='adv-item-detail'>平台可对外提供API，方便下游平台接入</div>
                  </div>
                  <div className='adv-item wow slideInRight' data-wow-offset="60">
                      <div className='adv-item-icon adv6'></div>
                      <div className='adv-item-title'>新手友好</div>
                      <div className='adv-item-detail'>通常上游容易拒绝没有量的客户，我们可提供平台内客户间对接，过渡更加平稳</div>
                  </div>
                  <div className='adv-item wow slideInRight' data-wow-offset="60">
                      <div className='adv-item-icon adv8'></div>
                      <div className='adv-item-title'>专属定制</div>
                      <div className='adv-item-detail'>接受客户定制化开发服务</div>
                  </div>
              </div>
          </div>
    </div>
  );
}

export default Adv;
