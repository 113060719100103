// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    background-color: #fff;
    padding: 0.52083rem;
    width: 7.29167rem;
    margin: 0 auto;
    text-align: left;
}
.about-content {
    font-size: 0.125rem;
    color: #474D58;
    letter-spacing: 0.01042rem;
}

@media screen and (max-width: 700px) {
    .about {
        width: 9.27083rem;
    }
    .about-content {
        font-size: 0.25rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAc;IACd,iBAAa;IACb,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,mBAAe;IACf,cAAc;IACd,0BAAmB;AACvB;;AAEA;IACI;QACI,iBAAa;IACjB;IACA;QACI,kBAAe;IACnB;AACJ","sourcesContent":[".about {\n    background-color: #fff;\n    padding: 100px;\n    width: 1400px;\n    margin: 0 auto;\n    text-align: left;\n}\n.about-content {\n    font-size: 24px;\n    color: #474D58;\n    letter-spacing: 2px;\n}\n\n@media screen and (max-width: 700px) {\n    .about {\n        width: 1780px;\n    }\n    .about-content {\n        font-size: 48px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
