
import { Component } from 'react';
import './Help.css';

class Help extends Component {
  linkToBlank(href) {
    window.open(href)
  }
  render() {
    return (
        <div className="help" id='help'>
        <div className='help-wrap'>
            <div className='help-center'>
              <span className='help-icon'></span>
              <p className='help-text'>帮助中心</p>
            </div>
            <div className='help-api' onClick={() => this.linkToBlank('http://api.mblyun.com/doc.html')}>
              <span className='api-icon'></span>
              <p className='help-text'>API文档</p>
            </div>
          </div>
        </div>
      );
  }
}

export default Help;
