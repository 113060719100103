import './Header.css';
import logo from '../../assets/images/logo.png'
import logoWhite from '../../assets/images/logo_white.png'
import React, { Component } from 'react';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeNav: 'home',
      currentMode: 'black',
      menuVisible: false,
    }
    this.scrollToAnchor = this.scrollToAnchor.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.getHeaderClassName = this.getHeaderClassName.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getMenuTogglerClass = this.getMenuTogglerClass.bind(this);
    this.getToggleIconClass = this.getToggleIconClass.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
     window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll() {
    const scrollTop = window.scrollY;
    const carousel = document.querySelector('.carousel');
    if (carousel) {
      const carouselHeight = carousel.clientHeight;
      if ((scrollTop + 150) >= carouselHeight) {
        this.setState({
          currentMode: 'white'
        })
      } else {
        this.setState({
          currentMode: 'black'
        })
      }
    }
  }
  scrollToAnchor(anchorName) {
    if (anchorName) {
      this.setState({ activeNav: anchorName, menuVisible: false });
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }
  getHeaderClassName() {
    return `header-wrap ${this.state.currentMode}`;
  }
  toggleMenu() {
    this.setState({
      menuVisible: !this.state.menuVisible
    })
  }
  getMenuTogglerClass() {
    const { menuVisible } = this.state;
    if (menuVisible) {
      return 'header-toggler wow feadIn visible'
    }
    return 'header-toggler wow feadIn'
  }
  getToggleIconClass() {
    const { currentMode } = this.state;
    if (currentMode === 'black') {
      return 'header-toggler-icon'
    }
    return 'header-toggler-icon blue'
  }
  handleCloseMenu() {
    this.setState({ menuVisible: false })  
  }
  render() {
    return (
      <div className={ this.getHeaderClassName() }>
        <header className='header'>
          <div className='header-logo'>
            {(this.state.currentMode === 'white' && !this.state.menuVisible)  ?
              <img alt='' src={logo} className="header-logo-img" />
              : <img alt='' src={logoWhite} className="header-logo-img" />}
            
          </div>
        <div className='header-nav'>
            <div className="header-nav-item" onClick={() => { this.scrollToAnchor('home') }}>
              首页
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('advantage') }}>
              平台优势
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('funintro') }}>
              功能介绍
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('hardware') }}>
              硬件产品
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('onlineexp') }}>
              在线体验
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('help') }}>
              帮助中心
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('help') }}>
              API文档
            </div>
            <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('about') }}>
              关于我们
            </div>
          </div>
          <div className={ this.getToggleIconClass() } onClick={() => { this.toggleMenu() }}></div>
          <div className={this.getMenuTogglerClass()}>
            <div className='header-logo'>
              <img alt='' src={logo} className="header-logo-img" />
            </div>
            <div className='header-toggler-box'>
              <div className="header-nav-item" onClick={() => { this.scrollToAnchor('home') }}>
                首页
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('advantage') }}>
                平台优势
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('funintro') }}>
                功能介绍
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('hardware') }}>
                硬件产品
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('onlineexp') }}>
                在线体验
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('help') }}>
                帮助中心
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('help') }}>
                API文档
              </div>
              <div className='header-nav-item' onClick={ () => { this.scrollToAnchor('about') }}>
                关于我们
              </div>
            </div>
            <div className='close-icon' onClick={this.handleCloseMenu}></div>
          </div>
        </header>
    </div>
  );
  }
}

export default Header;
