import './Title.css';

function Title(props) {
  return (
     <div className='title-wrap'>
        <div className='title'>{props.titleName}</div>
        <div className='title-border'></div>
    </div>
  );
}

export default Title;
