
import Title from '../Title/Title';
import './OnlineExp.css';

function OnlineExp() {
  return (
      <div className="online-exp" id='onlineexp'>
              <Title titleName="在线体验" />
              <div className='online-exp-list'>
              <div className='online-exp-list-item manageplatform'>
                  <p className='online-exp-list-item-title'>
                      管理后台
                  </p>
                  <a href='https://mng.mblyun.com' target="_blank" className='online-exp-list-item-url' rel="noreferrer">https://maike.cloud</a>
                  <div className='exp-account-info'>
                      <div className='exp-account-info-name'>管理员体验账号：</div>
                      <div className='exp-account-info-value'>oem_test</div>
                  </div>
                  <div className='exp-account-info'>
                      <div className='exp-account-info-name'> 密码：</div>
                      <div className='exp-account-info-value'>oem_test</div>
                  </div>
                  <div className='exp-account-split'></div>
                  <div className='exp-account-info'>
                      <div className='exp-account-info-name'>用户体验账号：</div>
                      <div className='exp-account-info-value'>18566295265</div>
                  </div>
                  <div className='exp-account-info'>
                      <div className='exp-account-info-name'> 密码：</div>
                      <div className='exp-account-info-value'>18566295265</div>
                  </div>
              </div>
              <div className='online-exp-list-item ercode'>
                  <p className='online-exp-list-item-title'>
                      小程序
                  </p>
                  <div className='online-exp-list-ercode'></div>
              </div>
              </div>
          </div>
  );
}

export default OnlineExp;
