
import Title from '../Title/Title';
import './Hardware.css';

function Hardware() {
  return (
    <div className="hardware" id='hardware'>
        <Title titleName="硬件产品" />
            <div className='hardware-list wow fadeInLeft' data-wow-delay="0.5">
              <div className='hardware-list-item'>
                  <div>
                    <div className='hardware-list-item-img'></div>
                    <div className='hardware-list-item-title'>电视盒子</div>
                  </div>
              </div>
              <div className='hardware-list-item'>
                  <div>
                    <div className='hardware-list-item-img router'></div>
                    <div className='hardware-list-item-title'>路由器</div>
                  </div>
              </div>
              <div className='hardware-list-item'>
                  <div>
                    <div className='hardware-list-item-img camera'></div>
                    <div className='hardware-list-item-title'>摄像头(开发中)</div>
                  </div>
                </div>
            </div>
     </div>
  );
}

export default Hardware;
