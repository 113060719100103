import { Component } from 'react';
import './Summary.css';

class Summary extends Component {
  constructor(props) {
    super(props)
    this.timer = null;
    this.state = {
      serviceCustomer: 0,
      platformNode: 0,
      upstrameBandwidth: 0
    }
    this.numberSetp = this.numberSetp.bind(this)
  }
  componentDidMount() {
    this.numberSetp();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
  numberSetp() {
    this.timer = setInterval(() => {
      const { serviceCustomer, platformNode, upstrameBandwidth } = this.state;
      if (serviceCustomer >= 300 && platformNode >= 100000 && upstrameBandwidth >= 10000) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (serviceCustomer < 300) {
        this.setState({ serviceCustomer: serviceCustomer + 15 });
      }
      if (platformNode < 100000) {
        this.setState({ platformNode: platformNode + 5000 });
      }
      if (upstrameBandwidth < 10000) {
        this.setState({ upstrameBandwidth: upstrameBandwidth + 500 });
      }
    }, 100);
  }
  render() {
    return (
    <div className="summary wow fadeInLeft">
        <div className='summary-item wow fadeIn' data-wow-duration="3s" data-wow-delay="0.5s">
          <p className='summary-item-number'>{ this.state.serviceCustomer }+</p>
              <p className='summary-item-sub-titile'>服务客户</p>
          </div>
          <div className='summary-item wow fadeIn' data-wow-duration="3s" data-wow-delay="0.5s">
              <p className='summary-item-number'>{ this.state.platformNode }+</p>
              <p className='summary-item-sub-titile'>平台节点</p>
          </div>
          <div className='summary-item wow fadeIn' data-wow-duration="3s" data-wow-delay="0.5s">
              <p  className='summary-item-number'>{ this.state.upstrameBandwidth }G+</p>
              <p className='summary-item-sub-titile'>上行带宽</p>
        </div>
    </div>
  );
  }
}

export default Summary;
