
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
          <div className='footer-dividline'></div>
          <div className='footer-content'>
              <div className='footer-content-copyright-content'>Copyright © 2021 Maike.cloud All Rights Reserved 北京麦珂科技有限公司</div>
              <div className='footer-content-dividline'></div>
              <div className='footer-content-copyright'></div>
              <div className='footer-content-copyright-link' onClick={() => { window.open('https://beian.miit.gov.cn/')}}>京ICP备2021030029号</div>
          </div>
     </div>
  );
}

export default Footer;
