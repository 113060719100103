
import Title from '../Title/Title';
import './About.css';

function About() {
  return (
    <div className="about" id='about'>
        <Title titleName="关于我们" />
          <div className='about-content wow fadeInDown' data-wow-delay="0.5s">
              Maike PCDN系统由北京麦珂科技有限公司开发，目前已对接多家主流平台，如派享云、七牛云、金山云（云帆）、聚沙、
              点心云、神鸟云等等，更多上游厂商（直签业务）也正在对接中。能为有搭建自营PCDN平台的客户提供更加快速、
              高效、稳定、性价比高的平台搭建服务。
        </div>
     </div>
  );
}

export default About;
