// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bg_product.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/product_tv.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/camera.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/images/router.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hardware {
    padding: 0.52083rem 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    /* height: 805px; */
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.hardware-list {
    display: flex;
    width: 7.29167rem;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
}

    
.hardware-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 1.66667rem;
    background-color: #fff;
}
.hardware-list-item-img {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    width: 1.10417rem;
    height: 0.91667rem;
    background-size: contain;
    margin: 0 auto;
}
.hardware-list-item-img.camera {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.hardware-list-item-img.router {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.hardware-list-item-title {
    margin-top: 0.05208rem;
    color: #474D58;
    font-size: 0.125rem;
    text-align: center;
}



@media screen and (max-width: 700px) {
    .hardware-list {
        width: 9.375rem;
    }
    .hardware-list-item {
        width: 2.77083rem;
        height: 2.1875rem;
    }
    .hardware-list-item-title {
        font-size: 0.25rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Hardware/Hardware.css"],"names":[],"mappings":"AAAA;IACI,qBAAkB;IAClB,mDAAqD;IACrD,mBAAmB;IACnB,sBAAsB;IACtB,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,iBAAa;IACb,cAAc;IACd,eAAe;IACf,8BAA8B;AAClC;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAY;IACZ,kBAAa;IACb,sBAAsB;AAC1B;AACA;IACI,yDAA2D;IAC3D,iBAAY;IACZ,kBAAa;IACb,wBAAwB;IACxB,cAAc;AAClB;AACA;IACI,yDAAuD;AAC3D;AACA;IACI,yDAAuD;AAC3D;AACA;IACI,sBAAgB;IAChB,cAAc;IACd,mBAAe;IACf,kBAAkB;AACtB;;;;AAIA;IACI;QACI,eAAa;IACjB;IACA;QACI,iBAAY;QACZ,iBAAa;IACjB;IACA;QACI,kBAAe;IACnB;AACJ","sourcesContent":[".hardware {\n    padding: 100px 0px;\n    background: url('../../assets/images/bg_product.png');\n    /* height: 805px; */\n    background-size: cover;\n    background-repeat: no-repeat;\n    box-sizing: border-box;\n}\n.hardware-list {\n    display: flex;\n    width: 1400px;\n    margin: 0 auto;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n    \n.hardware-list-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 432px;\n    height: 320px;\n    background-color: #fff;\n}\n.hardware-list-item-img {\n    background-image: url('../../assets/images/product_tv.png');\n    width: 212px;\n    height: 176px;\n    background-size: contain;\n    margin: 0 auto;\n}\n.hardware-list-item-img.camera {\n    background-image: url('../../assets/images/camera.svg');\n}\n.hardware-list-item-img.router {\n    background-image: url('../../assets/images/router.svg');\n}\n.hardware-list-item-title {\n    margin-top: 10px;\n    color: #474D58;\n    font-size: 24px;\n    text-align: center;\n}\n\n\n\n@media screen and (max-width: 700px) {\n    .hardware-list {\n        width: 1800px;\n    }\n    .hardware-list-item {\n        width: 532px;\n        height: 420px;\n    }\n    .hardware-list-item-title {\n        font-size: 48px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
