// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-wrap {
    margin-bottom: 0.52083rem;
}
.title {
    font-size: 0.20833rem;
    color: #474D58;
    text-align: center;
    margin-bottom: 0.10417rem;
    font-weight: 500;
    font-weight: 600;
}
.title-border {
    background: #2475EE;
    height: 0.02604rem;
    width: 0.41667rem;
    margin: 0 auto;
    border-radius: 0.01042rem;
}

@media screen and (max-width: 700px) {
    .title {
        font-size: 0.36458rem;
    }
    .title-border {
        background: #2475EE;
        height: 0.07813rem;
        width: 0.83333rem;
         border-radius: 0.04167rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.css"],"names":[],"mappings":"AAAA;IACI,yBAAoB;AACxB;AACA;IACI,qBAAe;IACf,cAAc;IACd,kBAAkB;IAClB,yBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,kBAAW;IACX,iBAAW;IACX,cAAc;IACd,yBAAkB;AACtB;;AAEA;IACI;QACI,qBAAe;IACnB;IACA;QACI,mBAAmB;QACnB,kBAAY;QACZ,iBAAY;SACX,yBAAkB;IACvB;AACJ","sourcesContent":[".title-wrap {\n    margin-bottom: 100px;\n}\n.title {\n    font-size: 40px;\n    color: #474D58;\n    text-align: center;\n    margin-bottom: 20px;\n    font-weight: 500;\n    font-weight: 600;\n}\n.title-border {\n    background: #2475EE;\n    height: 5px;\n    width: 80px;\n    margin: 0 auto;\n    border-radius: 2px;\n}\n\n@media screen and (max-width: 700px) {\n    .title {\n        font-size: 70px;\n    }\n    .title-border {\n        background: #2475EE;\n        height: 15px;\n        width: 160px;\n         border-radius: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
