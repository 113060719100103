// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary {
    width: 7.29167rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 1.04167rem;
    left: 50%;
    margin-left: -3.64583rem;
    position: absolute;
    bottom: -0.52083rem;
    z-index: 10;
}
.summary-item {
    width: 2.42708rem;
    text-align: center;
}
.summary-item-number {
    color:#2475EE;
    font-size: 0.41667rem;
    font-family: url;
    font-family: 'BakbakOne';
    margin: 0;
}

.summary-item-sub-titile {
    color: #ACB3BF;
    font-size: 0.125rem;
}

@media screen and (max-width: 700px) {
    .summary {
        width: 9.375rem;
        height: 1.25rem;
        bottom: -0.72917rem;
        margin-left: -4.6875rem;
    }
    .summary-item-sub-titile {
        font-size: 0.1875rem;
    }
}
@media screen and (max-width: 340px) {
    .summary {
        height: 1.45833rem;
        bottom: -0.9375rem;
        width: 9.375rem;
        margin-left: -4.6875rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Summary/Summary.css"],"names":[],"mappings":"AAAA;IACI,iBAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;IACtB,kBAAa;IACb,SAAS;IACT,wBAAmB;IACnB,kBAAkB;IAClB,mBAAc;IACd,WAAW;AACf;AACA;IACI,iBAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,qBAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,SAAW;AACf;;AAEA;IACI,cAAc;IACd,mBAAe;AACnB;;AAEA;IACI;QACI,eAAa;QACb,eAAa;QACb,mBAAc;QACd,uBAAmB;IACvB;IACA;QACI,oBAAe;IACnB;AACJ;AACA;IACI;QACI,kBAAa;QACb,kBAAc;QACd,eAAa;QACb,uBAAmB;IACvB;AACJ","sourcesContent":[".summary {\n    width: 1400px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #fff;\n    height: 200px;\n    left: 50%;\n    margin-left: -700px;\n    position: absolute;\n    bottom: -100px;\n    z-index: 10;\n}\n.summary-item {\n    width: 466px;\n    text-align: center;\n}\n.summary-item-number {\n    color:#2475EE;\n    font-size: 80px;\n    font-family: url;\n    font-family: 'BakbakOne';\n    margin: 0px;\n}\n\n.summary-item-sub-titile {\n    color: #ACB3BF;\n    font-size: 24px;\n}\n\n@media screen and (max-width: 700px) {\n    .summary {\n        width: 1800px;\n        height: 240px;\n        bottom: -140px;\n        margin-left: -900px;\n    }\n    .summary-item-sub-titile {\n        font-size: 36px;\n    }\n}\n@media screen and (max-width: 340px) {\n    .summary {\n        height: 280px;\n        bottom: -180px;\n        width: 1800px;\n        margin-left: -900px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
