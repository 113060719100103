
import { Component } from 'react';
import Title from '../Title/Title';
import './FunIntro.css';

class FunIntro extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // alert(JSON.stringify(document.getElementById('testdom').style))
    }
    render() {
        return (
            <div className="fun-intro" id='funintro'>
                    <Title titleName="功能介绍" />
                    <div className='fun-intro-wrap'>
                        <div className='fun-intro-center'>
                        </div>
                        <div className='fun-intro-cycle'>
                        </div>
                        <div className='fun-intro-second-cycle'>
                        </div>
                        <div className='fun-intro-item fun1-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            <div className='fun-intro-item-icon fun1'></div>
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>管理系统</p>
                                <p className='fun-intro-item-content-detail' id='testdom'>用户管理、设备管理、节点管理、实时监控、收益率调整、
                                    统计报表、异常告警、结算周期自由设置（按日周或月）</p>
                            </div>
                </div>
                <div className='fun-intro-item fun2-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            <div className='fun-intro-item-icon fun2'></div>
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>合同管理</p>
                                <p className='fun-intro-item-content-detail'>平台与客户可在线签署合同，更加方便高效</p>
                            </div>
                </div>
                <div className='fun-intro-item fun3-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            <div className='fun-intro-item-icon fun3'></div>
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>代理分佣</p>
                                <p className='fun-intro-item-content-detail'>支持多级代理，更好激励代理推广市场</p>
                            </div>
                </div>
                <div className='fun-intro-item fun4-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            <div className='fun-intro-item-icon fun4'></div>
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>终端程序</p>
                                <p className='fun-intro-item-content-detail'>多端支持，实时上报设备基础信息（CPU、内存、NAT类型等），
        动态切换上游厂商，支持命令模式，支持多拨，光猫代理</p>
                            </div>
                </div>
                <div className='fun-intro-item fun5-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>支付接入</p>
                                <p className='fun-intro-item-content-detail'>已对接多家灵活用工平台，帮助客户解决税务问题
        方便用户多渠道提现（支付宝，微信，银行卡）</p>
                </div>
                <div className='fun-intro-item-icon fun5'></div>
                </div>
                <div className='fun-intro-item fun6-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>商城</p>
                                <p className='fun-intro-item-content-detail'>打通自有供应链，方便用户在线购物
        帮助平台消耗用户余额，减少提现压力</p>
                </div>
                <div className='fun-intro-item-icon fun6' ></div>
                </div>
                <div className='fun-intro-item fun7-item wow fadeInUp' data-wow-offset="60" data-wow-delay="0.5s">
                            <div className='fun-intro-item-content'>
                                <p className='fun-intro-item-content-title'>小程序</p>
                                <p className='fun-intro-item-content-detail'>用户可绑定节点、查看收益、提现</p>
                </div>
                <div className='fun-intro-item-icon fun7'></div>
                    </div>
                </div>
                
                </div>
        );
    }
}


export default FunIntro;
